import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-countdown-timer',
    templateUrl: './countdown-timer.component.html',
    styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {
    s: any;
    m: any;
    h: any;
    d: any;
    @Input() event_date: string;
    @Input() show_days: Boolean;
    @Input() show_event_from_array: Boolean;

    constructor() {}

    ngOnInit() {
        if (this.show_event_from_array) {
            this.event_date = this.getClosestTime();
        }

        this.countdown(this.event_date);
    }

    getClosestTime() {
        const currentDate = moment().toArray(); // [2013, 1, 4, 14, 40, 16, 154];
        const currentUnix = moment().unix();

        // Array of [HH, mm, ss] in ASC order
        const eventTimes = [
            [7, 0, 0], 
            [7, 15, 0], 
            [7, 30, 0], 
            [7, 45, 0], 
            [8, 0, 0], 
            [8, 15, 0], 
            [8, 30, 0], 
            [8, 45, 0], 
            [9, 0, 0], 
            [9, 15, 0], 
            [9, 30, 0], 
            [9, 45, 0], 
            [10, 0, 0], 
            [10, 15, 0], 
            [10, 30, 0], 
            [10, 45, 0], 
            [11, 0, 0], 
            [11, 15, 0], 
            [11, 30, 0], 
            [11, 45, 0], 
            [12, 0, 0], 
            [12, 15, 0], 
            [12, 30, 0], 
            [12, 45, 0], 
            [13, 0, 0], 
            [13, 15, 0], 
            [13, 30, 0], 
            [13, 45, 0], 
            [14, 0, 0], 
            [14, 15, 0], 
            [14, 30, 0], 
            [14, 45, 0], 
            [15, 0, 0], 
            [15, 15, 0], 
            [15, 30, 0], 
            [15, 45, 0], 
            [16, 0, 0], 
            [16, 15, 0], 
            [16, 30, 0], 
            [16, 45, 0], 
            [17, 0, 0], 
            [17, 15, 0], 
            [17, 30, 0], 
            [17, 45, 0], 
            [18, 0, 0], 
            [20, 0, 0]
            ];
        const currentYearMonthDay = [currentDate[0], currentDate[1], currentDate[2]];
        let eventTimesUnix = [];

        // Turn Events to Unix Timestamp so that we could easily find time differences
        for (const eventTime of eventTimes) {
            const unixEvent = moment([...currentYearMonthDay, ...eventTime, 0]).unix();
            eventTimesUnix.push(unixEvent);
        }

        // Default to 30 min away
        const thirtyMinAway = moment()
            .add(30, 'minute')
            .toArray();
        let closestTime = [thirtyMinAway[3], thirtyMinAway[4], thirtyMinAway[5]];

        // Get closest time based on unix
        for (let i = 0; i < eventTimesUnix.length; i++) {
            const timeDiff = eventTimesUnix[i] - currentUnix;

            if (timeDiff > 0) {
                closestTime = eventTimes[i];
                break;
            }
        }

        const dateArray = [
            currentDate[0], // year
            currentDate[1], // month
            currentDate[2], // day
            closestTime[0], // hour
            closestTime[1], // min
            closestTime[2], // sec
            0 // millisec
        ];

        const closestDate = moment(dateArray).format('YYYY-MM-DD HH:mm:ss');
        return closestDate;
    }

    countdown(eventString) {
        const now = new Date();

        const eventDate = moment(eventString, 'YYYY-MM-DD HH:mm:ss').toDate();
        const currentTime = now.getTime();
        const eventTime = eventDate.getTime();

        const remTime = eventTime - currentTime;

        let s = Math.floor(remTime / 1000);
        let m = Math.floor(s / 60);
        let h = Math.floor(m / 60);
        const d = Math.floor(h / 24);

        h = h % 24;
        m = m % 60;
        s = s % 60;

        // Prepend 0 if below 10;
        this.d = d < 10 ? `0${d}` : d;
        this.h = h < 10 ? `0${h}` : h;
        this.m = m < 10 ? `0${m}` : m;
        this.s = s < 10 ? `0${s}` : s;

        // Show 0 if passed;
        this.d = d < 0 ? '0' : d;
        this.h = h < 0 ? '0' : h;
        this.m = m < 0 ? '0' : m;
        this.s = s < 0 ? '0' : s;

        setTimeout(() => {
            this.countdown(eventString);
        }, 1000);
    }
}
