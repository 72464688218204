import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {

  public server = {
    // api: 'http://localhost:3002',
    // panel: 'http://localhost:3001'
    api: environment.api,
    panel: environment.panel,
    site: environment.site
  };

  public organization = {
    oid: environment.oid,
    moduleId: environment.moduleId,
    scalewithmoduleId: environment.scalewithmoduleId
  };

  constructor() {
  }


}
