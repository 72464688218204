import { Component, OnInit } from '@angular/core';
import { GlobalsService } from 'src/app/globals.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  panelUrl: string;

  constructor(private globals: GlobalsService) {
    this.panelUrl = globals.server.panel;


  }

  ngOnInit() {
  }

}
