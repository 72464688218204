import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountdownTimerComponent } from './components/countdown-timer/countdown-timer.component';
import { FooterComponent } from './components/footer/footer.component';
import { NgwWowModule } from 'ngx-wow';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavbarModule, WavesModule, ButtonsModule, IconsModule } from 'angular-bootstrap-md'

@NgModule({
  declarations: [
    CountdownTimerComponent,
    FooterComponent,
    NavbarComponent
    ],
  imports: [
    CommonModule,
    NavbarModule,
    WavesModule,
    ButtonsModule,
    IconsModule
  ],
  exports: [
    CountdownTimerComponent,
    FooterComponent,
    NgwWowModule,
    NavbarComponent
  ]

})
export class SharedModule { }
