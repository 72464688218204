import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { WebinarComponent } from './webinar/webinar.component';

import { ClassroomComponent } from './classroom/classroom.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ScaleWithSoftwareComponent } from './scalewithsoftware/scalewithsoftware.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'scalewithsoftware',
    component: ScaleWithSoftwareComponent
  },
  {
    path: 'terms',
    component: TermsComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'classroom',
    component: ClassroomComponent
  },
  {
    path: 'ultraaccelerator',
    component: HomeComponent,
        resolve: {
            url: 'externalUrlRedirectResolver'
        },
        data: {
            externalUrl: 'https://howtoscalesoftware.com/checkout'
        }
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes //{ enableTracing: true } // <-- debugging purposes only
    )],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
      {
          window.location.href = (route.data as any).externalUrl;
      }
  }
  ]
})
export class AppRoutingModule { }
