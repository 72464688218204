import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import { trigger, transition, useAnimation } from '@angular/animations';
import { shake } from 'ngx-animate';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { GlobalsService } from '../globals.service';
import { WindowRef } from '../services/window.service';
@Component({
    selector: 'app-scalewithsoftware',
    templateUrl: './scalewithsoftware.component.html',
    styleUrls: ['./scalewithsoftware.component.scss'],
    animations: [
        trigger('shake', [
            transition(
                '* => shake',
                useAnimation(shake, {
                    // Set the duration to 5seconds and delay to 2seconds
                    params: { timing: 0.7 },
                })
            ),
        ])
    ],
})
export class ScaleWithSoftwareComponent implements OnInit {
    nextWebinar = '2019-10-03 12:00:00';
    todaysTime;
    submitted: Boolean = false;
    loginFormSubmitted: Boolean = false;
    changePasswordFormSubmitted: Boolean = false;
    animationState;
    isLoading: Boolean = false;
    showError: Boolean;
    errorMessage: string;
    status = 'optin'; // create_password || login_user
    isCheckShowing: Boolean = false;

    signupForm = this.formBuilder.group({
        name: ['', Validators.required],
        email: ['', [Validators.required, Validators.email]],
    });

    changePasswordForm = this.formBuilder.group({
        password: ['', [Validators.required, Validators.minLength(8)]],
    });

    loginForm = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]],
        password: ['', [Validators.required, Validators.minLength(8)]],
    });

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private auth: AuthService,
        private globals: GlobalsService,
        private winRef: WindowRef
    ) {}

    ngOnInit() {
        this.calculateTodaysTime();
    }

    calculateTodaysTime() {
        const today = moment();
        const eventDate = moment(this.nextWebinar, 'YYYY-MM-DD HH:mm:ss');
        const isToday = today.startOf('day').isSame(eventDate.startOf('day'));

        if (isToday) {
            this.todaysTime = moment(this.nextWebinar).format('[TODAY @] h:mm A'); // TODAY @ 3:40 PM
        } else {
            this.todaysTime = 'Next Webinar is in:';
        }
    }

    onSubmitRegistration() {
        this.submitted = true;
        if (!this.signupForm.valid) {
            console.log('Form is invalid...');
            this.shake();
        } else {
            console.log('Submitting form  ...');

            this.isLoading = true;

            const registrationData = {
                ...this.signupForm.value,
                oid: this.globals.organization.oid,
                moduleId: this.globals.organization.scalewithmoduleId,
            };

            this.auth.optin(registrationData).subscribe(
                (res: any) => {
                    console.log('HTTP response', res);
                    this.isLoading = false;

                    if (res.isNewUser) {
                        this.auth.setToken(res.token);
                        this.status = 'create_password';
                    } else {
                        this.showError = true;
                        this.errorMessage =
                            'We noticed that your already a member of Upclub. Enter your Upclub password.';

                        this.loginForm.patchValue({ email: registrationData.email });
                        this.status = 'login_user';
                    }
                },
                err => {
                    this.showError = true;
                    this.errorMessage = 'Error processing request. Please try again or contact system admin for more info.';
                    console.log('HTTP Error', err);
                    this.isLoading = false;
                },
                () => console.log('Completed')
            );
        }
    }

    onPasswordSubmit() {
        this.changePasswordFormSubmitted = true;
        if (!this.changePasswordForm.valid) {
            console.log('Form is invalid...');
            this.shake();
        } else {
            console.log('Submitting form  ...');

            this.isLoading = true;
            const token = this.auth.getToken();
            const loginData = { ...this.changePasswordForm.value, token: token };
            console.log(loginData);
            this.auth.changePassword(loginData).subscribe(
                (res: any) => {
                    console.log('HTTP response', res);
                    this.showSuccessMessage(token);
                },
                err => {
                    console.log('HTTP Error', err);
                    this.showError = true;
                    this.errorMessage = 'Error processing request. Please try again or contact system admin for more info.';
                    this.isLoading = false;
                },
                () => console.log('Completed')
            );
        }
    }

    goBack() {
        this.showError = false;
        this.status = 'optin';
    }

    showSuccessMessage(token) {
        console.log('Funnelytics session: ', this.winRef.nativeWindow.funnelytics.session);
        this.isLoading = true;
        const funnelytics_session = this.winRef.nativeWindow.funnelytics.session ? `&_fs=${this.winRef.nativeWindow.funnelytics.session}`: '';
        // setTimeout(() => {
        this.isLoading = false;
        const utm_source = this.globals.server.site;
        const utm_medium = 'website';
        const utm_campaign = 'gettingstarted';

        // window.open(
        //     `${this.globals.server.panel}/login?access_token=${token}&redirectUrl=/training/lessons/${this.globals.organization.moduleId}${funnelytics_session}&utm_campaign=${utm_campaign}`,
        //     '_blank'
        // );
        window.location.href = `${this.globals.server.panel}/login?access_token=${token}&redirectUrl=/training/lessons/${this.globals.organization.scalewithmoduleId}${funnelytics_session}&utm_campaign=${utm_campaign}`;
        // }, 1000);
    }

    showCheckMarkAndThankYou(token) {
        this.isLoading = true;
        this.isCheckShowing = true;

        setTimeout(() => {
            this.isLoading = false;
            this.isCheckShowing = false;
            window.location.href = `${this.globals.server.panel}/login?access_token=${token}&redirectUrl=/training/lessons/${this.globals.organization.scalewithmoduleId}`;
        }, 3000);
    }

    onLoginUser() {
        this.loginFormSubmitted = true;
        if (!this.loginForm.valid) {
            console.log('Form is invalid...');
            this.shake();
        } else {
            console.log('Submitting form  ...');

            this.isLoading = true;

            const loginData = {
                ...this.loginForm.value,
                oid: this.globals.organization.oid,
                moduleId: this.globals.organization.scalewithmoduleId,
            };

            this.auth.loginAndAddmodule(loginData).subscribe(
                (res: any) => {
                    console.log('HTTP response', res);
                    this.isLoading = false;

                    this.showSuccessMessage(res.token);
                },
                err => {
                    this.showError = true;
                    switch (err.status) {
                        case 401:
                            this.errorMessage = 'Your password is incorrect. Please try again.';
                            break;
                        default:
                            this.errorMessage = 'Error processing request. Please try again or contact system admin for more info.';
                            break;
                    }
                    console.log('HTTP Error', err);
                    this.isLoading = false;
                },
                () => console.log('Completed')
            );
        }

        // TODO: Use EventEmitter with form value
        console.warn(this.signupForm);
    }

    shake() {
        this.animationState = 'shake';
        setTimeout(() => {
            this.animationState = '';
        }, 1000);
    }

    get signupFormNameInput() {
        return this.signupForm.get('name');
    }

    get signupFormEmailInput() {
        return this.signupForm.get('email');
    }

    get changePasswordFormPasswordInput() {
        return this.changePasswordForm.get('password');
    }

    get loginFormEmailInput() {
        return this.loginForm.get('email');
    }

    get loginFormPasswordInput() {
        return this.loginForm.get('password');
    }
}
