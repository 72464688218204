import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
    providedIn: "root"
})
export class ClassroomService {
    constructor(private http: HttpClient) {}

    lessonsUrl = 'assets/json/lessons.json';

    getLessons() {
        return this.http.get(this.lessonsUrl);
    }
}
