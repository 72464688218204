import { Component, OnInit } from '@angular/core';
import { ClassroomService } from './classroom.service';

@Component({
  selector: 'app-classroom',
  templateUrl: './classroom.component.html',
  styleUrls: ['./classroom.component.scss']
})
export class ClassroomComponent implements OnInit {

  constructor(private classroom: ClassroomService) { }

  lessons: any;

  ngOnInit() {
    this.getLessons();
  }

  getLessons() {
    this.classroom.getLessons()
      .subscribe((data) => {
        console.log({data});
        this.lessons = data;
      });
  }

}
