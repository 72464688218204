import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { delay } from 'rxjs/operators';
import { GlobalsService } from '../globals.service';

@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(private http: HttpClient, private globals: GlobalsService) {}

    optin(userInfo) {
      return this.http.post(`${this.globals.server.api}/v1/training/webinar/optin`, userInfo);
    }

    changePassword(userInfo) {
        return this.http.post(`${this.globals.server.api}/v1/training/webinar/change-password`, userInfo);
    }

    loginAndAddmodule(userInfo) {
        return this.http.post(`${this.globals.server.api}/v1/training/webinar/login`, userInfo);
    }

    public setToken(token) {
      return localStorage.setItem('token', token);
    }

    public getToken(): string {
      return localStorage.getItem('token');
    }
}
